.DozentIn-container {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    width: 100%;
}


.DozentIn-header {
    padding: 64px 0;
    text-align: center;
}

.DozentIn-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 auto;
    width: 100%; /* Stellt sicher, dass die volle Breite genutzt wird */
}
