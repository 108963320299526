.toolbar {
  display: flex;
  flex-direction: column;
  padding: 0 1em;
  min-height: 90px; /* Adjusted height to fit both header sections */
}

.upper-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.upper-header {
flex-direction: row;
}

.left-section {
  display: flex;
  align-items: center;
  gap: 1em;
}

.logo-image {
  max-width: 70px;
  max-height: 70px;
  margin-right: 10px;
  filter: brightness(0) invert(1);
}

.title {
  flex-grow: 1;
  font-size: 1.5em; /* Adjusted for responsive scaling */
  white-space: nowrap;
}

.center-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  flex-grow: 1;
}

.center-section .MuiButton-root {
  font-size: 1em;
  padding: 0.5em 1em;
  font-weight: bold;
  white-space: nowrap;
}

.right-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
}

@media (max-width: 1500px) {
  .title {
    font-size: 1.2em;
  }
  
  .center-section .MuiButton-root {
    font-size: 0.9em;
  }

  .profile-pic {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 1100px) {
  .toolbar {
    padding: 0 0.5em;
  }
  
  .upper-header {
    flex-direction: column;
  }

  .title {
    font-size: 1em;
  }

  .center-section {
    flex-direction: row;
    gap: 0.5em;
  }

  .center-section .MuiButton-root {
    font-size: 0.8em;
    padding: 0.25em 0.5em;
  }

  .profile-pic {
    width: 30px;
    height: 30px;
  }
}
