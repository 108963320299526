.quiz-container {
  background-color: var(--accent-color);
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.quiz-question {
  margin-bottom: 1em;
  font-weight: bold;
}

.quiz-answers {
  margin: 1em 0;
}

/* Base styles for answer labels */
.quiz-container .MuiFormControlLabel-root {
  display: block;
  margin: 10px 0;
  transition: all 0.3s ease;
}

/* Remove disabled state overrides */
.quiz-container .MuiFormControlLabel-root.Mui-disabled {
  opacity: 1 !important;
}

/* Correct answer styles */
.quiz-container .MuiFormControlLabel-root.answer-correct {
  color: var(--accept-color) !important;
  font-weight: bold !important;
}

/* Incorrect answer styles */
.quiz-container .MuiFormControlLabel-root.answer-incorrect {
  color: var(--warning-color) !important;
  font-weight: bold !important;
}

/* Should have selected styles */
.quiz-container .MuiFormControlLabel-root.answer-should-selected {
  color: var(--accept-color) !important;
  font-weight: bold !important;
  opacity: 0.7;
}

/* Reset state */
.quiz-container.reset .MuiFormControlLabel-root {
  color: inherit !important;
  font-weight: normal !important;
}

.quiz-container button {
  margin-top: 20px;
}