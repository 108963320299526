.hero-section {
    position: relative;
    background-image: url('./assets/hero-bg.jpg');
    background-size: cover;
    background-position: center;
    padding: 40px 0;
    margin-top: 90px; /* Abstand zum Header */
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--custom-white);
    border-radius: 8px;
}

.hero-content {
    position: relative;
    background-color: rgba(0, 0, 0, 0.4); /* Halbtransparenter schwarzer Hintergrund */
    padding: 20px;
    border-radius: 8px;
    z-index: 2;
}

.hero-section h1 {
    font-size: 2.5rem;
}

.hero-section p {
    font-size: 1.5rem;
}

.hero-section button {
    margin-top: 20px;
}

.container {
    margin-top: 20px;
    min-height: 100vh; /* sorgt dafür, dass der Container die volle Höhe einnimmt */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Footer immer unten */
}

.content-box {
    padding: 20px;
    border-radius: 8px;
    backdrop-filter: blur(10px); /* Hinzufügen eines Unschärfe-Effekts */
    color: var(--custom-white);
}

/* Spezifische Styles für die "Warum Doctrina mobilis?" Boxen */
.accent-box {
    background-color: var(--accent-color);
    color: var(--text-color); /* Textfarbe auf dem Akzent-Hintergrund */
}

/* Spezifische Styles für die "Funktionen der Plattform" Boxen */
.primary-box {
    background-color: var(--accent-color);
    color: var(--text-color); /* Textfarbe auf dem primären Hintergrund */
}

.section {
    margin-bottom: 40px; /* Erhöht den vertikalen Abstand zwischen den Abschnitten */
}

.footer {
    margin-top: auto;
    width: 100%;
}
