#container {
  display: grid;
  grid-template-areas: 
    "haupt aside"
    "haupt aside"
    "haupt aside";
  grid-template-columns: auto 350px;
  grid-template-rows: auto 1fr;
  gap: 0px;
  height: 100vh;
  overflow-x: hidden;
}

body {
  background-color: var(--secondary-color);
  margin: 0;
  overflow-x: hidden;
}

header, main {
  border: 1px solid var(--custom-white);
  background-color: var(--secondary-color);
  padding: 1em;
  text-align: center;
  box-sizing: border-box;
}

nav {
  overflow-y: auto;
}

main {
  grid-area: haupt;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%; 
  max-width: none;
  box-sizing: border-box;
}

#kapitel-termine {
  grid-area: aside;
  width: 350px;
}

.kapitel-header {
  padding: 2rem 1rem;
  text-align: center;
  margin-bottom: 2rem;
}

.kapitel-header h1 {
  font-size: 5vw;
  font-weight: bold;
}

.kapitel-header h5 {
  font-size: 3vw;
  font-weight: normal;
}

.kapitel-content {
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 1rem;
}

.kapitel-content img,
.kapitel-content video,
.kapitel-content iframe {
  max-width: 100%;
  height: auto;
  width: 100% !important;
  max-width: 800px !important;
  height: auto !important;
  margin: 1em auto !important;
  display: block !important;
}

.quiz-editor-preview {
  display: none;
}

@media (max-width: 1000px) {
  #container {
    grid-template-areas: 
      "haupt haupt"
      "haupt haupt"
      "haupt haupt";
    grid-template-columns: 1fr;
    width: 100%;
  }

  #kapitel-termine {
    display: none;
  }
}

@media (max-width: 800px) {
  #container {
    grid-template-areas: 
      "haupt haupt"
      "haupt haupt"
      "haupt haupt";
    grid-template-columns: 1fr;
  }

  .kapitel-content img,
  .kapitel-content video,
  .kapitel-content iframe {
    max-width: 100% !important;
  }
}

@media (max-width: 600px) {
  header, nav, main, aside {
    text-align: left;
  }
}