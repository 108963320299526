.termine-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.termine {
  padding: 1em;
  background-color: var(--accent-color);
  overflow-y: auto;
  height: auto; /* Adjust height based on content */
  border-radius: 15px;
  box-sizing: border-box; /* Include padding in the width */
  width: 350px; /* Fixed width to ensure it matches the grid template */
  text-align: center; /* Center the text inside the aside */
}

.termine h2 {
  font-size: 2rem; /* Increase the font size */
  font-weight: bold; /* Make the text bold */
  margin: 1rem 0; /* Add some margin for spacing */
}

.termin-kachel {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  border: 1px solid var(--custom-white);
  padding: 1em;
  background-color: var(--custom-white);
  color: var(--text-color);
  border-radius: 15px;
  box-sizing: border-box; /* Include padding in the width */
  width: 100%; /* Ensure it fits within the container */
}

.datum-bereich {
  text-align: center;
  margin-right: 1em;
}

.wochentag {
  font-size: 1.5em;
  font-weight: bold;
}

.datum {
  font-size: 0.9em;
  font-weight: bold;
}

.uhrzeit {
  font-size: 0.8em;
  font-weight: bold;
}

.kurs-bereich {
  flex-grow: 1;
}

.kursname {
  font-size: 1.2em;
  font-weight: bold;
}

.ort {
  font-size: 1em;
}

@media (max-width: 600px) {
  .termin-kachel {
    flex-direction: column;
    align-items: flex-start;
  }

  .datum-bereich {
    margin-right: 0;
    margin-bottom: 0.5em;
  }

  .kurs-bereich {
    width: 100%;
  }
}
