.course-navigation {
  width: 250px;
  transition: all 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--secondary-color) !important;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0,0,0,0.5);
  overflow-y: auto;
  transform: translateX(-100%);
  padding: 1em; /* Add padding for better spacing */
}

.course-navigation.open {
  transform: translateX(0);
}

.toggle-button {
  font-size: 20px;
  cursor: pointer;
  background-color: var(--accent-color) !important;
  color: black !important;
  padding: 10px 15px;
  border: 2px solid var(--primary-color) !important; 
  border-radius: 0 5px 5px 0;
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 1001;
  transition: background-color 0.3s ease;
  transform: translateY(-50%);
}

.toggle-button.open {
  left: 250px;
  border-radius: 5px 0 0 5px;
}


.course-navigation h2 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.course-navigation h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.course-navigation .chapter-name .MuiListItemText-primary {
  font-weight: bold !important;
}

.course-navigation .subchapter-item {
  padding-left: 32px;
}

.course-navigation a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 18px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.course-navigation a:hover {
  color: var(--custom-white) !important;
}

.main-content {
  transition: margin-left 0.5s;
}

.shifted {
  margin-left: 250px;
}

.navigation-buttons {
  margin-top: 2rem; /* Add margin to separate from the course list */
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Add space between buttons */
}

.navigation-buttons button {
  background-color: var(--accent-color) !important;
  color: black !important;
}

@media (max-width: 600px) {
  .course-navigation {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .course-navigation.open {
    transform: translateX(0);
  }

  .toggle-button {
    display: block; /* Ensure the button is displayed in mobile view */
    left: 0; /* Ensure the button stays at the left edge */
    right: auto; /* Override the right positioning */
    width: 50px; /* Set a fixed width for the button */
  }

  .toggle-button.open {
    right: 0; /* Ensure the button stays at the right edge when the sidebar is open */
    left: auto; /* Override the left positioning */
    width: 50px; /* Set a fixed width for the button */
  }
}
