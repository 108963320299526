.ekg-quiz-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.ekg-image-container {
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
}

.ekg-image {
  width: 100%;
  height: auto;
}

.zoom-button {
  position: absolute !important;
  right: 10px;
  bottom: 10px;
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.selected-items-box {
  min-height: 300px !important;
  max-height: 300px !important;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
}

.selected-items-title {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.MuiAccordionDetails-root .MuiButton-text {
  color: var(--accent-color) !important;
}

.MuiAccordionDetails-root .MuiButton-text:hover {
  background-color: rgba(var(--accent-color-rgb), 0.1) !important;
}

.action-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.ekg-image-full {
  width: auto !important;
  height: auto !important;
  max-width: 95% !important;
  max-height: 90vh !important;
  object-fit: contain !important;
  cursor: grab;
  touch-action: none !important;
}

.ekg-image-full:active {
  cursor: grabbing;
}

.zoom-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  padding: 5px;
  z-index: 1000;
  display: flex;
  gap: 5px;
  width: fit-content;
}

.MuiDialog-paper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
  overflow: hidden !important;
}

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
}

.react-transform-component {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.dialog-close-button {
  position: absolute !important;
  top: 10px;
  right: 10px;
  z-index: 1200;
  color: white !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.dialog-close-button:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

@media (max-width: 600px) {
  .react-transform-component {
    padding: 20px !important;
    touch-action: none !important;
    user-select: none !important;
  }

  .ekg-image-full {
    max-width: 100% !important;
    max-height: 85vh !important;
  }

  .MuiDialog-paper {
    margin: 0 !important;
    padding: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    max-width: none !important;
    max-height: none !important;
  }

  .zoom-controls {
    bottom: 20px;
    background: rgba(255, 255, 255, 0.9);
    padding: 8px;
  }

  .dialog-close-button {
    top: 20px;
    right: 20px;
  }
}