/* src/EinführungEchokardiographie.css */
.article {
  padding: 20px;
  max-width: 1500;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  text-align: left;
}

.article h1, .article h2 {
  color: #333;
  text-align: center;
}

.article p {
  margin: 1em 0;
}

.article ul {
  margin: 1em 0;
  padding-left: 20px;
}

.article ul li {
  margin-bottom: 0.5em;
}

.article a {
  color: var(--primary-color);
  text-decoration: none;
}

.article a:hover {
  text-decoration: underline;
}

.article-image {
  width: 100%;
  height: auto;
  margin: 20px 0;
}
