/* Entfernt Hover-Hintergrundfarbe für alle Accordions */
.MuiAccordion-root:hover {
    background-color: var(--primary-color) !important; /* Deaktiviert Hover-Hintergrund */
}

/* Entfernt Hover-Hintergrundfarbe für AccordionSummary */
.MuiAccordionSummary-root:hover {
    background-color: var(--primary-color) !important; /* Deaktiviert Hover-Hintergrund */
}

/* Spezifische Regel für Buttons, um sicherzustellen, dass sie nicht vom Hover-Effekt betroffen sind */
.MuiButton-root:hover {
    background-color: var(--accent-color)!important; /* Erlaubt Hover-Effekt auf Buttons */
}
.termine {
    padding: 1em;
    background-color: var(--accent-color);
    overflow-y: auto;
    height: 100%;
    border-radius: 15px;
    box-sizing: border-box; /* Include padding in the width */
  }
  
  .termin-kachel {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    border: 1px solid var(--custom-white);
    padding: 1em;
    background-color: var(--custom-white);
    color: var(--text-color);
    border-radius: 15px;
    box-sizing: border-box; /* Include padding in the width */
    width: 100%; /* Ensure it fits within the container */
  }
  
  .datum-bereich {
    text-align: center;
    margin-right: 1em;
  }
  
  .wochentag {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .datum {
    font-size: 0.9em;
    font-weight: bold;
  }
  
  .uhrzeit {
    font-size: 0.8em;
    font-weight: bold;
  }
  
  .kurs-bereich {
    flex-grow: 1;
  }
  
  .kursname {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .ort {
    font-size: 1em;
  }
  
  @media (max-width: 600px) {
    .termin-kachel {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .datum-bereich {
      margin-right: 0;
      margin-bottom: 0.5em;
    }
  
    .kurs-bereich {
      width: 100%;
    }

    /* In der CSS-Datei: Kursuebersicht.css */
    .dialog-content {
        display: flex;
        flex-direction: column;
        gap: 15px; 
      }
      
    
      .custom-time-picker .react-time-picker__inputGroup__input {
        width: 50px;
        height: 40px;
      }
      
      .custom-time-picker .react-time-picker__wrapper {
        border: 1px solid var(--primary-color);
        padding: 10px;
        border-radius: 8px;
        background-color: var(--secondary-color);
      }
      
      .MuiDialog-paper {
        padding: 20px;
        border-radius: 10px;
      }
      
    

  }

/* Kursuebersicht.css */
.kapitel-item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 10px;
  background-color: var(--primary-color);
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 10px;
}

.kapitel-title {
  flex: 1;
  min-width: 200px;
}

.kapitel-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .kapitel-item {
    flex-direction: column;
    align-items: stretch;
  }

  .kapitel-title {
    margin-bottom: 10px;
  }

  .kapitel-buttons {
    justify-content: flex-start;
    button {
      flex: 1;
    }
  }
}