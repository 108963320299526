.user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    max-width: 100%; /* Volle Breite einnehmen */
    margin: 0 auto;
    width: 1800px;
  }
  
  .profile-pic-wrapper {
    position: relative;
    text-align: center;
    margin-bottom: 2em;
  }
  
  .profile-pic-large {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .profile-pic-upload-button {
    color: var(--primary-color); /* Referenziert die Farbe aus colors.css */
  }
  
  .profile-form {
    width: 100%; /* Formularelemente sollen die volle Breite einnehmen */
    max-width: 800px; /* Begrenze die maximale Breite */
    margin: 0 auto;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
    width: 100%;
    max-width: 700px; /* Angepasste maximale Breite */
    justify-content: center; /* Zentriert die Formgruppe */
  }
  
  .form-group label {
    flex: 0 0 175px;
    font-weight: bold;
    text-align: right;
  }
  
  .profile-input {
    flex: 1;
    min-width: 250px; /* Mindestbreite erhöhen */
    max-width: 100%; /* Nutzt die volle verfügbare Breite */
    background-color: var(--custom-white); /* Weißer Hintergrund für die Eingabefelder */
  }
  
  .button-group {
    display: flex;
    gap: 1em;
    justify-content: center;
    width: 100%; /* Knöpfe zentrieren und die volle Breite nutzen */
    max-width: 700px; /* Begrenze die maximale Breite */
  }
  
  .save-button {
    background-color: var(--accept-color) !important;
    color: var(--custom-white) !important;
    font-weight: bold !important;
  }
  
  .cancel-button {
    background-color: var(--warning-color) !important;
    color: var(--custom-white) !important;
    font-weight: bold !important;
  }
  