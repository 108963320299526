.nachricht-accordion .MuiAccordionSummary-root {
  background-color: var(--secondary-color) !important;
}

.nachricht-accordion .MuiAccordionSummary-root:hover {
  background-color: var(--secondary-color) !important;
}

.nachricht-accordion .MuiAccordionSummary-content {
  color: var(--text-color);
}

.reply-textfield {
  width: calc(100% - 16px) !important;
  margin: 0 8px !important;
  background-color: var(--custom-white) !important;
}

.reply-textfield .MuiInputBase-root {
  background-color: var(--custom-white) !important;
}

.reply-button {
  width: 120px !important;
  height: 36px !important;
  margin: 4px auto !important;
  min-height: unset !important;
}

.reply-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin: 8px 0;
}

.reply-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
  max-width: 300px;
  margin-top: 8px;
}

@media (max-width: 600px) {
  .reply-textfield .MuiInputBase-root {
    width: 100% !important;
  }
  
  .reply-button {
    flex: 1;
    max-width: 160px !important;
  }
  
  .reply-buttons {
    max-width: 100%;
  }
}