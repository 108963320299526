/* Globale Fixes in AdminPage.css */
body {
  overflow-x: hidden;  /* Horizontales Scrollen verhindern */
  margin: 0;
  padding: 0;
}

.admin-container {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px; /* Maximale Breite für große Bildschirme */
    margin: 0 auto; /* Zentriert den Container */
    overflow-x: hidden;  /* Horizontales Scrollen im Container verhindern */
}

.admin-header {
    padding: 2rem 1rem; /* Responsive Padding mit rem */
    text-align: center;
    margin-bottom: 2rem;
}

.admin-header h1 {
    font-size: 5vw; /* Dynamische Schriftgröße basierend auf der Viewport-Breite */
    font-weight: bold; /* Fett */
}

.admin-header h5 {
    font-size: 3vw; /* Dynamische Schriftgröße basierend auf der Viewport-Breite */
    font-weight: normal; /* Nicht fett */
}

.admin-list {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Responsive Abstände */
    margin: 0 auto;
    width: 100%;
    max-width: 800px; /* Maximale Breite für bessere Lesbarkeit */
}

/* Media Queries für verschiedene Bildschirmgrößen */
@media (max-width: 768px) {
    .admin-container {
        padding: 10px;
        width: 100vw;      /* Volle Viewport-Breite */
        max-width: 100%;   /* Maximale Breite begrenzen */
    }

    .admin-header {
        padding: 1rem;
    }

    .admin-header h1 {
        font-size: 6vw; /* Dynamische Schriftgröße basierend auf der Viewport-Breite */
    }

    .admin-header h5 {
        font-size: 4vw; /* Dynamische Schriftgröße basierend auf der Viewport-Breite */
    }
}

@media (max-width: 480px) {
    .admin-header h1 {
        font-weight: bold !important; /* Überschreibt MUI Styles */
        font-size: 8vw !important; /* Dynamische Schriftgröße basierend auf der Viewport-Breite */
    }
    
    .admin-header h5 {
        font-size: 5vw !important; /* Dynamische Schriftgröße basierend auf der Viewport-Breite */
    }
}
