.kurs-content {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Video container for maintaining aspect ratio */
.kurs-content .video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  max-width: 800px;
  margin: 1em auto;
}

/* Style for embedded iframes (YouTube) */
.kurs-content .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/* Regular media */
.kurs-content img,
.kurs-content video {
  width: 100% !important;
  max-width: 800px !important;
  height: auto !important;
  margin: 1em auto !important;
  display: block !important;
}

@media (max-width: 800px) {
  .kurs-content img,
  .kurs-content video,
  .kurs-content .video-container {
    max-width: 100% !important;
  }
}