.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  max-width: 800px;
  margin: 20px auto;
  width: 95%;
  box-sizing: border-box;
}

.profile-pic-wrapper {
  position: relative;
  text-align: center;
  margin: 20px auto;
}

.profile-pic-large {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-pic-upload-button {
  color: var(--primary-color); /* Referenziert die Farbe aus colors.css */
}

.profile-form {
  width: 100%; /* Formularelemente sollen die volle Breite einnehmen */
  max-width: 800px; /* Begrenze die maximale Breite */
  margin: 0 auto;
  box-sizing: border-box;
}

.form-group {
  display: grid;
  grid-template-columns: minmax(120px, 30%) 1fr auto;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
  width: 100%;
  max-width: 700px; /* Angepasste maximale Breite */
  justify-content: center; /* Zentriert die Formgruppe */
}

.form-group label {
  font-size: 0.9rem;
  flex: 0 0 150px;
  font-weight: bold;
  text-align: right;
}

.profile-input {
  flex: 1;
  min-width: 0; /* Mindestbreite erhöhen */
  max-width: 100%; /* Nutzt die volle verfügbare Breite */
  background-color: var(--custom-white); /* Weißer Hintergrund für die Eingabefelder */
  width: 100%;
}

.form-group span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  justify-content: center;
  width: 100%; /* Knöpfe zentrieren und die volle Breite nutzen */
  max-width: 700px; /* Begrenze die maximale Breite */
  margin-top: 20px;
}

.save-button {
  background-color: var(--accept-color) !important;
  color: var(--custom-white) !important;
  font-weight: bold !important;
  width: 100%;
  padding: 10px !important;
}

.cancel-button {
  background-color: var(--warning-color) !important;
  color: var(--custom-white) !important;
  font-weight: bold !important;
  width: 100%;
  padding: 10px !important;
}

@media (max-width: 480px) {
  .user-profile {
    align-items: flex-start;
    margin: 10px;
    padding: 5px;
  }

  .profile-form {
    margin: 0;  /* Remove auto margin */
  }

  .form-group {
    grid-template-columns: 100px 1fr auto;
    justify-content: start;  /* Links ausrichten */
    margin: 0 0 15px 0;  /* Nur unten Margin */
    max-width: 100%;
  }
  
  .form-group label {
    font-size: 0.8rem;
    text-align: left;  /* Text links ausrichten */
  }

  .button-group {
    margin: 20px 0;  /* Nur vertikaler Margin */
    justify-content: start;
  }
}
