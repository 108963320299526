.login {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
}

.login form {
  display: flex;
  flex-direction: row;
  gap: 1em;
  width: 100%;
}

.input-field .MuiOutlinedInput-root {
  background-color: var(--custom-white) !important;
  color: black;
  height: auto;
}

.login-button {
  background-color: var(--accent-color) !important;
  color: black !important; /* Textfarbe auf Schwarz geändert */
  font-weight: bold !important;
  font-size: 1rem; /* Standard Schriftgröße */
}

.login-button:hover {
  background-color: var(--custom-white) !important;
  color: var(--primary-color) !important; /* Ändert die Farbe beim Hover */
}


.login p {
  margin: 0;
  background-color: var(--accent-color);
  color: var(--warning-color);
}

.register-link {
  color: var(--custom-white) !important;
  font-size: 0.875rem;
  text-decoration: underline !important;
  text-transform: none !important; /* Verhindert Großbuchstaben-Darstellung */
  font-weight: normal;
}

.register-link:hover {
  text-decoration: underline;
}

/* Media Queries for responsiveness */
@media (max-width: 1100px) {

  .input-field .MuiOutlinedInput-root {
    font-size: 0.8rem; /* Even smaller font size */
    padding: 0.3em; /* Even smaller padding */
    height: 40px;
  }
  
  .login-button {
    font-size: 0.8rem; /* Smaller font size on smaller screens */
  }

  .register-link {
    font-size: 0.75rem; /* Smaller font size on smaller screens */
  }
}

@media (max-width: 480px) {
  .login-button {
    font-size: 0.8rem; /* Even smaller font size on very small screens */
  }

  .register-link {
    font-size: 0.7rem; /* Even smaller font size on very small screens */
  }
}
