#container {
  display: grid;
  grid-template-areas: 
    "haupt aside"
    "haupt aside"
    "haupt aside";
  grid-template-columns: auto 350px; /* Fixed size for the aside column */
  grid-template-rows: auto 1fr;
  gap: 0px;
}

body {
  background-color: var(--secondary-color);
  margin: 0; /* Ensure no default margin on body */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

header, main {
  border: 1px solid var(--custom-white);
  background-color: var(--secondary-color);
  padding: 1em;
  text-align: center;
}

nav {
  overflow-y: auto;
}

main {
  grid-area: haupt;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%; 
  max-width: none; /* Sicherstellen, dass die volle Breite genutzt wird */
}

#mainpage-termine {
  grid-area: aside;
  
}

@media (max-width: 1000px) {
  #container {
    grid-template-areas: 
      "haupt haupt"
      "haupt haupt"
      "haupt haupt";
    grid-template-columns: 1fr;
    width: 100%; /* Stelle sicher, dass das Container-Element die gesamte Breite einnimmt */
  }

  #mainpage-termine {
    display: none; /* Hide the Termine section */
  }
}

@media (max-width: 800px) {
  #container {
    grid-template-areas: 
      "haupt haupt"
      "haupt haupt"
      "haupt haupt";
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  header, nav, main, aside {
    text-align: left;
  }
}
