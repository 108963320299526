.faq-section {
    position: relative;
    background-color: var(--secondary-color);
    padding: 60px 20px;
    margin-top: 30px;
    border-radius: 8px;
    text-align: center;
    z-index: 1;
}

.faq-tabs {
    margin-bottom: 30px;
}

.faq-tabs .MuiTab-root {
    font-weight: bold;
    color: var(--primary-color);
    transition: color 0.3s ease;
}

.faq-tabs .MuiTab-root.active-tab {
    color: var(--custom-white);
    background-color: var(--primary-color); /* Hintergrundfarbe auf Primary Color geändert */
    border-radius: 8px;
}

.faq-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
    z-index: 2; 
    position: relative; 
}

/* Sicherstellen, dass alle Akkordeons die gewünschten Farben verwenden */
.MuiAccordion-root {
    background-color: var(--primary-color) !important;
    color: var(--custom-white) !important;
    border-radius: 8px;
}

.MuiAccordion-root:hover {
    background-color: var(--accent-color) !important;
}

.MuiAccordionSummary-root {
    background-color: transparent !important;
    color: var(--custom-white) !important;
}

.MuiAccordionSummary-content {
    color: var(--custom-white) !important;
}

.MuiAccordionDetails-root {
    background-color: transparent !important;
    color: var(--custom-white) !important;
}

.faq-item {
    background-color: var(--primary-color) !important;
    color: var(--custom-white) !important;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow: hidden;
    z-index: 3; 
    position: relative; 
}

.faq-item:hover {
    background-color: var(--primary-color) !important;
}

.faq-item h6 {
    margin-bottom: 10px;
}

.faq-item p {
    margin-bottom: 0;
    margin-top: 10px;
    color: var(--custom-white);
}

/* Accordion Expand Icon Color */
.MuiAccordionSummary-expandIcon {
    color: var(--custom-white);
}
