.kontakt-section {
    background-color: var(--accent-color);
    padding: 40px 20px;
    border-radius: 8px;
    text-align: center;
}

.kontakt-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
}

.kontakt-form input,
.kontakt-form textarea {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid var(--primary-color);
    font-size: 1rem;
    background-color: var(--custom-white);
    color: var(--text-color);
}

/* Add specific styles for multiline TextField */
.kontakt-form .MuiOutlinedInput-multiline {
    padding: 0 !important;
}

.kontakt-form .MuiOutlinedInput-multiline .MuiOutlinedInput-input {
    padding: 0;
}

/* Target MUI multiline input root */
.kontakt-form .MuiInputBase-multiline {
    padding: 0 !important;
}

.kontakt-form .send-button,
.kontakt-form .cancel-button {
    flex: 1;
    margin: 0 5px;
}

.kontakt-form .send-button {
    background-color: var(--primary-color) !important;
    color: var(--custom-white) !important;
    font-weight: bold;
}

.kontakt-form .cancel-button {
    background-color: var(--warning-color) !important;
    color: var (--custom-white) !important;
    font-weight: bold;
}

.kontakt-form .send-button:hover {
    background-color: var(--accent-color) !important;
}

.kontakt-form .cancel-button:hover {
    background-color: var(--custom-white) !important;
    color: var(--warning-color) !important;
    border: 1px solid var(--warning-color);
}
