.kurse-section {
    position: relative;
    background-color: var(--secondary-color);
    padding: 60px 20px;
    margin-top: 30px;
    border-radius: 8px;
    text-align: center;
}

.kurse-section h2 {
    color: var(--primary-color);
    margin-bottom: 20px;
}

.kurse-section p {
    color: var(--text-color);
    margin-bottom: 40px;
}

.kurse-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
    z-index: 2; 
    position: relative; 
}

.kurse-item {
    background-color: var(--primary-color);
    color: var(--custom-white);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow: hidden;
    z-index: 3; 
    position: relative; 
}

.kurse-item:hover {
    background-color: var(--primary-color) !important;
}

.kurse-item h6 {
    margin-bottom: 10px;
}

.kurse-item p {
    margin-bottom: 0;
    margin-top: 10px;
    color: var(--custom-white);
}

/* Spezifische Styles für den "Erfahre mehr"-Button */
.kurse-item .MuiButton-containedSecondary {
    background-color: var(--accent-color) !important;
    color: var(--text-color) !important; /* Textfarbe auf var(--text-color) gesetzt */
    font-weight: bold !important;
    margin-top: 10px;
}

/* Accordion-Styles spezifisch für Kurse */
.kurse-item.MuiAccordion-root {
    background-color: var(--primary-color) !important;
    color: var(--custom-white) !important;
}

.kurse-item.MuiAccordion-root:hover {
    background-color: var(--primary-color) !important;
}

/* Accordion Expand Icon Color */
.kurse-item.MuiAccordionSummary-expandIcon {
    color: var(--custom-white);
}
